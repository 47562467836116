import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Container } from "@mui/material";

import { Button, Burger } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { IconX, IconSparkles } from "@tabler/icons-react";
import { motion } from "framer-motion";

import Logo from "../../Assets/IMG/Logo.png";
import AboutUsIcon from "../../Assets/IMG/Navbar/AboutUs.svg";
import AiChatIcon from "../../Assets/IMG/Navbar/AiChat.svg";
import DownloadAppIcon from "../../Assets/IMG/Navbar/DownloadApp.svg";

import "./styles.scss";

export default function Navbar() {
  const [isNavOpened, { toggle: ToggleNav, close: CloseNav }] =
    useDisclosure(false);
  const NavItems = [
    { label: "About Us", link: "about" },
    { label: "AI Chat", link: "chat" },
    { label: "Features", link: "features", scroll: true },
    { label: "Contact Us", link: "contact", scroll: true },
  ];
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.replaceAll("/", "");
  return (
    <>
      <Burger
        opened={isNavOpened}
        onClick={ToggleNav}
        className="burger"
        aria-label="Toggle navigation"
      />
      {path !== "chat" && (
        <>
          <div className="nav-container flex-row align-center justify-center">
            <Container maxWidth="lg">
              <nav className="nav flex-row align-center justify-between">
                <Link to="/">
                  <img src={Logo} alt="" className="logo" />
                </Link>
                <div className="flex-row align-center justify-between items">
                  {NavItems.map((item, index) => {
                    return (
                      <>
                        {item.scroll ? (
                          <a href={`#${item.link}`}>
                            <span className="link">{item.label}</span>
                          </a>
                        ) : (
                          <Link to={`/${item.link}`} key={index}>
                            <span className="link">{item.label}</span>
                          </Link>
                        )}
                      </>
                    );
                  })}
                  <a href="#waitlist">
                    <Button className="download">Join Waitlist</Button>
                  </a>
                </div>
              </nav>
            </Container>
          </div>
        </>
      )}
      <motion.div
        initial={false}
        animate={{
          opacity: isNavOpened ? 1 : 0,
          display: isNavOpened ? "block" : "none",
        }}
        className="small-nav"
      >
        <div className="flex-row width-100 align-end justify-end">
          <span className="close" onClick={CloseNav}>
            <IconX color="var(--blue-white)" stroke={3} size={20} />
          </span>
        </div>
        <div className="items flex-col justify-between">
          <Link
            onClick={CloseNav}
            to="/about"
            className="flex-row item align-center"
          >
            <img src={AboutUsIcon} alt="" className="icon" />
            <div className="label">About Us</div>
          </Link>
          <Link
            onClick={CloseNav}
            to="/chat"
            className="flex-row item align-center"
          >
            <img src={AiChatIcon} alt="" className="icon" />
            <div className="label">AI Chat</div>
          </Link>
          <a href="#waitlist">
            <Button className="download" leftSection={<IconSparkles />}>
              Join Waitlist
            </Button>
          </a>
        </div>
      </motion.div>
      {path !== "chat" && <div className="nav-placeholder"></div>}
    </>
  );
}
