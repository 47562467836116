import { useState } from "react";
import { Link } from "react-router-dom";

import { Container, Grid, GridProps } from "@mui/material";

import { ActionIcon, Avatar, Button, Text, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import {
  IconPlus,
  IconSparkles,
  IconLayoutSidebarRightExpand,
  IconLayoutSidebarLeftExpand,
  IconThumbUpFilled,
  IconThumbDownFilled,
  IconPencil,
  IconRotateClockwise,
} from "@tabler/icons-react";

import { motion } from "framer-motion";
import Image from "../../Assets/IMG/Chat/AI.svg";
import SendIcon from "../../Assets/IMG/Chat/Send.svg";
import BubblesIcon from "../../Assets/IMG/Chat/Bubbles.svg";
import Logo from "../../Assets/IMG/Logo.png";

import "./styles.scss";
import { getEllipsisWithString } from "../../Lib/Methods";
export default function Chat() {
  const prompts = [
    {
      title: "Can you explain the basics of managing diabetes",
      tag: "For a young adult",
    },
    {
      title: "Common symptoms of diabetic retinopathy",
      tag: "Early stages",
    },
    {
      title: "Dietary recommendations for diabetes",
      tag: "For a middle-aged man",
    },
    {
      title: "Lifestyle changes for diabetes",
      tag: "For a Chef",
    },
    {
      title: "Risk factors for retinopathy",
      tag: "Blindness risk",
    },
    {
      title: "Preventing diabetic retinopathy",
      tag: "Eye check frequency",
    },
  ];
  const gridItemProps: GridProps = {
    item: true,
    xl: 4,
    lg: 4,
    md: 6,
    // sm: 6,
    // xs: 6,
  };
  const [isChatActive, setChatActive] = useState<boolean>(false);
  const [isChatWindowActive, setChatWindowActive] = useState<boolean>(false);
  const [
    isSidebarOpen,
    { open: OpenSidebar, close: CloseSidebar, toggle: ToggleSidebar },
  ] = useDisclosure(true);

  const chats = [
    {
      header: "Yesterday",
      items: [
        { title: "Eye check frequency?" },
        { title: "Explain how to manage diabetic retinopathy somethn somethn" },
        { title: "What are the common symptoms of being a senior man" },
        {
          title:
            "How can I prevent diabetic retinopathy from costing me a millyon billyon dollars",
        },
      ],
    },
    {
      header: "Last Week",
      items: [
        { title: "Eye check frequency?" },
        { title: "Explain how to manage diabetic retinopathy somethn somethn" },
      ],
    },
    {
      header: "Last Month",
      items: [
        { title: "Retinopathy reversibility" },
        { title: "Explain how to manage diabetic retinopathy somethn somethn" },
      ],
    },
  ];
  const messages = [
    {
      received: false,
      message:
        "What factors increase the risk of developing diabetic retinopathy?",
    },
    {
      received: true,
      message: `Several factors can increase the risk of developing diabetic retinopathy, a complication of diabetes that affects the eyes. One of the primary risk factors is the duration of diabetes. The longer someone has diabetes, especially if it's poorly controlled, the higher the risk of developing retinopathy. Consistently elevated blood sugar levels can damage the small blood vessels in the retina, leading to retinopathy over time.  <br/> Moreover, individuals with poorly managed hypertension (high blood pressure) and those with elevated levels of cholesterol are at an increased risk. High blood pressure and cholesterol levels can further damage the delicate blood vessels in the retina, exacerbating the condition. <br /> <br /> Pregnancy can also be a contributing factor, particularly for women with pre-existing diabetes. The hormonal changes and metabolic demands of pregnancy can impact blood sugar levels, potentially worsening diabetic retinopathy. <br /> Furthermore, tobacco use, whether through smoking or chewing tobacco, is strongly associated with an increased risk of diabetic retinopathy. The chemicals in tobacco can constrict blood vessels, reducing blood flow to the retina and exacerbating damage caused by diabetes. <br /> <br /> In summary, managing diabetes effectively through lifestyle modifications, medication adherence, and regular monitoring of blood sugar levels is crucial for reducing the risk of diabetic retinopathy. Additionally, controlling hypertension and cholesterol levels, avoiding tobacco use, and seeking regular eye examinations are essential steps in preventing or minimizing the progression of retinopathy.`,
    },
  ];
  return (
    <div className="chat-container flex-row">
      <motion.div
        initial={false}
        animate={{
          left: isSidebarOpen ? "0px" : "-400px",
        }}
        className="left flex-col align-center"
      >
        <div className="top flex-row align-center justify-center">
          <motion.div
            className="toggle-sidebar-container"
            initial={false}
            animate={{
              left: isSidebarOpen ? "250px" : "10px",
            }}
          >
            <ActionIcon
              variant="subtle"
              className="toggle-sidebar"
              onClick={ToggleSidebar}
              color="var(--blue-tertiary)"
            >
              {isSidebarOpen ? (
                <IconLayoutSidebarRightExpand />
              ) : (
                <IconLayoutSidebarLeftExpand />
              )}
            </ActionIcon>
          </motion.div>
          <Link to="/">
            <img src={Logo} alt="" className="logo" />
          </Link>
        </div>
        {!isChatActive ? (
          <>
            <Button
              className="button new"
              leftSection={<IconPlus stroke={2} size={15} />}
              onClick={() => {
                setChatActive(true);
              }}
            >
              New Chat
            </Button>
            <a href="#waitlist">
              <Button
                className="button download"
                leftSection={<IconSparkles size={15} />}
              >
                Join Waitlist
              </Button>
            </a>
          </>
        ) : (
          <>
            <Button
              className="button new"
              leftSection={<IconPlus stroke={2} size={15} />}
            >
              New Chat
            </Button>
            <br />
            {chats.map((chat, index) => {
              return (
                <div className="chat flex-col" key={index}>
                  <Text className="header" tt="uppercase">
                    {chat.header}
                  </Text>
                  <div className="items flex-col">
                    {chat.items.map((item, index) => {
                      return (
                        <Button
                          key={index}
                          variant="subtle"
                          className="item"
                          onClick={() => {
                            setChatWindowActive(true);
                          }}
                          leftSection={
                            <img className="icon" src={BubblesIcon} />
                          }
                        >
                          <Text className="label">
                            {getEllipsisWithString(item.title, 26)}
                          </Text>
                        </Button>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <a href="#waitlist">
              <Button
                className="button download"
                leftSection={<IconSparkles size={15} />}
              >
                Join Waitlist
              </Button>
            </a>
          </>
        )}
      </motion.div>
      <Container maxWidth="lg">
        {isChatWindowActive ? (
          <div className="chat-window flex-col">
            {messages.map((message, index) => {
              return (
                <>
                  {message.received ? (
                    <div
                      key={index}
                      className="flex-row received-container justify-between width-100"
                    >
                      <img src={Image} alt="" className="avatar" />
                      <div className="message">
                        <Text className="content">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: message.message,
                            }}
                          ></span>
                        </Text>
                        <div className="actions-container flex-row align-center width-100 justify-end">
                          <div className="actions flex-row align-center justify-between">
                            <IconThumbUpFilled className="action" />
                            <IconThumbDownFilled className="action dislike" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="flex-row sent-container justify-between width-100"
                    >
                      <div className="message flex-row align-center justify-between">
                        <Text className="content">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: message.message,
                            }}
                          ></span>
                        </Text>
                        <ActionIcon className="edit" variant="subtle">
                          <IconPencil
                            size={19.5}
                            color="var(--blue-transparent)"
                          />
                        </ActionIcon>
                      </div>
                      <Avatar
                        color="var(--blue-transparent)"
                        className="avatar"
                      />
                    </div>
                  )}
                </>
              );
            })}
            <div className="regenerate-row flex-row align-center justify-center width-100">
              <Button
                className="regenerate"
                leftSection={
                  <IconRotateClockwise size={16.5} className="icon" />
                }
              >
                <Text className="label">Regenerate Response</Text>
              </Button>
            </div>
            <div className="flex-row align-center send-row">
              <TextInput
                spellCheck={false}
                w="-webkit-fill-available"
                classNames={{
                  input: "input",
                  wrapper: "input-wrapper",
                }}
                placeholder="Ask Fin a Question"
              />
              <ActionIcon className="send-btn">
                <img src={SendIcon} alt="" className="send-icon" />
              </ActionIcon>
            </div>
          </div>
        ) : (
          <div className="new-chat-container flex-col align-center justify-center">
            <div className="top flex-col align-center width-100">
              <img src={Image} className="image" alt="" />
              <Text className="header">How can I assist you today?</Text>
            </div>
            <div className="bottom flex-col align-center justify-between width-100">
              <Grid
                container
                className="prompts"
                spacing={3}
                alignItems="center"
                alignContent="center"
                justifyContent="center"
              >
                {prompts.map((prompt, index) => {
                  return (
                    <Grid key={index} {...gridItemProps}>
                      <div className="prompt flex-col justify-between">
                        <Text className="title">{prompt.title}</Text>
                        <Text className="tag">{prompt.tag}</Text>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
              <div className="flex-row align-center send-row">
                <TextInput
                  spellCheck={false}
                  w="-webkit-fill-available"
                  classNames={{
                    input: "input",
                    wrapper: "input-wrapper",
                  }}
                  placeholder="Ask Fin a Question"
                />
                <ActionIcon className="send-btn">
                  <img src={SendIcon} alt="" className="send-icon" />
                </ActionIcon>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
