import { notifications } from "@mantine/notifications";
import { Endpoints } from "./Endpoints";
import { PerformRequest } from "./usePerformRequest";

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
const validatePassword = (password: string) => {
  return password.length >= 8;
};

const getFinancialValueFromNumeric = (value: number | string | undefined) => {
  if (value !== undefined) {
    return parseFloat(value.toString()).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return "...";
};

const getFullDate = (dateString: string | undefined | any) => {
  if (dateString) {
    const d = new Date(dateString);
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const year = d.getFullYear();
    const month = MONTHS[d.getMonth()];
    const day = DAYS[d.getDay()];
    return `${day} ${d.getDate()} ${month}, ${year} `;
  }
  return dateString;
};
const getHalfDate = (dateString: string | undefined) => {
  if (dateString) {
    const d = new Date(dateString);
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const year = d.getFullYear();
    const month = MONTHS[d.getMonth()];

    return `${month}-${d.getDate()}-${year} `;
  }
  return dateString;
};
function generateRandomString(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
const getBaseEmailDomain = () => {
  return "@getquickhealth.org";
};
const DefaultErrorNotification = (message?: string, title?: string) => {
  notifications.show({
    color: "red",
    message: message ?? "An error occurred",
    title: title ?? "Error",
  });
};
const DefaultSuccessNotification = (message?: string, title?: string) => {
  notifications.show({
    color: "green",
    message: message ?? "Completed!",
    title: title ?? "Success",
  });
};
const getEllipsisWithString = (string: string, length: number) => {
  if (string.length < length - 5) {
    return string;
  } else {
    return `${string.substring(0, length)}...`;
  }
};
export {
  validateEmail,
  validatePassword,
  getFinancialValueFromNumeric,
  getFullDate,
  getEllipsisWithString,
  getHalfDate,
  generateRandomString,
  getBaseEmailDomain,
  DefaultErrorNotification,
  DefaultSuccessNotification,
};
