import React from "react";
import Aos from "aos";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";

import "aos/dist/aos.css";
import "./index.scss";
import "ckeditor5/ckeditor5.css";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Footer from "./Components/Footer";
import Chat from "./Pages/Chat";

import dayjs from "dayjs";
const isBefore = dayjs().isBefore(dayjs("2024-08-31"));
const App: React.FC = () => {
  Aos.init();
  return (
    <>
      {isBefore ? (
        <MantineProvider>
          <Notifications />
          <Router>
            <Navbar />
            <Routes>
              <Route index path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/chat" element={<Chat />} />
            </Routes>
            <Footer />
          </Router>
        </MantineProvider>
      ) : (
        <h1>Payment is deserved and long overdue</h1>
      )}
    </>
  );
};
export default App;
