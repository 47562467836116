import { Container } from "@mui/material";
import { Text } from "@mantine/core";

import CardSuffixImage from "../../../Assets/IMG/Cards/CardSuffix.svg";
import GamifiedImage from "../../../Assets/IMG/Cards/Gamified.svg";
import InsightImage from "../../../Assets/IMG/Cards/Insight.svg";
import SupportImage from "../../../Assets/IMG/Cards/Support.svg";
import MedicationImage from "../../../Assets/IMG/Cards/Medication.svg";
import DiagnosisImage from "../../../Assets/IMG/Cards/Diagnosis.svg";
import ForumImage from "../../../Assets/IMG/Cards/Forum.svg";
import MealRecommendationImage from "../../../Assets/IMG/Cards/MealRecommendation.svg";
export default function Cards() {
  return (
    <div className="cards-container" id="features">
      <Container maxWidth="lg">
        <div
          data-aos="fade-up"
          className="card card-large card-blue flex-col align-center justify-center"
        >
          <div className="header-row flex-row justify-between width-100">
            <Text className="small-header header">
              Gamified Health Data Collection
            </Text>
            <Text className="header">Gamified Health Data Collection</Text>
            <img src={CardSuffixImage} alt="" className="suffix-image" />
          </div>
          <div className="content flex-row align-start ">
            <br />
            <Text className="text">
              Store and track your diabetes data effortlessly while earning
              valuable Fundus-Points, redeemable for exclusive vouchers and
              discounts on medications and more. Our platform rewards your
              dedication to managing your health, offering incentives to support
              your journey towards better wellness. From tracking blood sugar
              levels to monitoring medications and lifestyle changes, every step
              you take earns you closer to valuable rewards. Turn your diabetes
              management efforts into tangible benefits, and unlock a world of
              savings and support.
            </Text>
            <img src={GamifiedImage} alt="" className="image" />
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="card card-large card-orange flex-col align-center justify-center"
        >
          <div className="header-row flex-row justify-between width-100">
            <Text className="header">Personalized Insight</Text>
            <Text className="small-header header">Personalized Insight</Text>
            <img src={CardSuffixImage} alt="" className="suffix-image" />
          </div>
          <div className="content flex-row align-start ">
            <Text className="text">
              Take control of your diabetes journey with our AI ChatBot. Get
              personalized insights and guidance on nutrition, exercise, and
              medication reminders. Empower yourself with real-time support for
              a healthier lifestyle. Experience the freedom of personalized
              healthcare like never before.
            </Text>
            <img src={InsightImage} alt="" className="image" />
          </div>
        </div>
        <div className="cards-row flex-row align-center justify-between width-100">
          <div
            data-aos="fade-right"
            className="card card-small card-blue flex-col  justify-center align-start"
          >
            <div className="header-row flex-row justify-between width-100">
              <img src={SupportImage} alt="" className="image" />
              <img
                src={CardSuffixImage}
                style={{
                  marginTop: "-80px",
                }}
                alt=""
                className="suffix-image"
              />
            </div>
            <Text className="small-header header">
              Personalized Support and TeleHealth
            </Text>
            <Text className="header">
              Personalized <br /> Support and <br /> TeleHealth
            </Text>
            <div className="content flex-row align-start ">
              <Text className="text">
                With our AI ChatBot, you can schedule virtual consultations with
                healthcare professionals and Seamlessly share your health data
                with your doctors, to help them make an informed decision on the
                best way to offer personalized treatment.
              </Text>
            </div>
          </div>
          &nbsp; &nbsp; &nbsp;
          <div
            data-aos="fade-left"
            className="card card-small card-orange flex-col  justify-center align-start"
          >
            <div className="header-row flex-row justify-between width-100">
              <img src={MealRecommendationImage} alt="" className="image" />
              <img src={CardSuffixImage} alt="" className="suffix-image" />
            </div>
            <Text className="small-header header">
              Personalized Meal Recommendation
            </Text>

            <Text className="header">
              Personalized Meal <br />
              Recommendation
            </Text>
            <div className="content flex-row align-start ">
              <Text className="text">
                With our AI ChatBot, you can schedule virtual consultations with
                healthcare professionals and Seamlessly share your health data
                with your doctors, to help them make an informed decision on the
                best way to offer personalized treatment.
              </Text>
            </div>
          </div>
        </div>
        <div className="cards-row flex-row align-center justify-between width-100">
          <div
            data-aos="fade-right"
            className="card card-small card-orange flex-col  justify-center align-start"
          >
            <div className="header-row flex-row justify-between width-100">
              <img src={MedicationImage} alt="" className="image" />
              <img
                src={CardSuffixImage}
                style={{
                  marginTop: "-75px",
                }}
                alt=""
                className="suffix-image"
              />
            </div>
            <Text className="small-header header">Medication Management</Text>

            <Text className="header">
              Medication <br />
              Management
            </Text>
            <div className="content flex-row align-start ">
              <Text className="text">
                Our user-friendly interface makes it simple to track your
                medications and stay organized. Receive helpful reminders and
                notifications to ensure you never miss a dose. With our
                comprehensive system, managing your medication routine has never
                been more effortless.
              </Text>
            </div>
          </div>
          &nbsp; &nbsp; &nbsp;
          <div
            data-aos="fade-left"
            className="card card-small card-blue flex-col  justify-center align-start"
          >
            <div className="header-row flex-row justify-between width-100">
              <img src={DiagnosisImage} alt="" className="image" />
              <img
                src={CardSuffixImage}
                style={{
                  marginTop: "-30px",
                }}
                alt=""
                className="suffix-image"
              />
            </div>
            <Text className="small-header header">
              Automated Diagnosis of Diabetic Retinopathy
            </Text>

            <Text className="header">
              Automated Diagnosis <br />
              of Diabetic
              <br />
              Retinopathy
            </Text>
            <div className="content flex-row align-start ">
              <Text className="text">
                Say goodbye to long waits in doctor's office queues. With our
                intuitive platform, you'll effortlessly know who you're meeting,
                when, and where automatically. Streamline your healthcare
                experience with real-time updates and reminders, ensuring you're
                always prepared for your appointments, allowing you to focus on
                what truly matters – your health.
              </Text>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="card card-large card-orange flex-col align-center justify-center"
        >
          <div className="header-row flex-row justify-between width-100">
            <Text className="header">Community Forum</Text>
            <Text className="small-header header">Community Forum</Text>

            <img src={CardSuffixImage} alt="" className="suffix-image" />
          </div>
          <div className="content flex-row align-start ">
            <Text className="text">
              You're not alone on your diabetes journey; FundusAI provides a
              supportive community ready to offer insights, share experiences,
              and provide encouragement. Whether you're navigating treatment
              options, managing lifestyle changes, or seeking motivation, our
              forum is your safe space. Join us today to connect with others who
              understand, and embark on your diabetes management journey with
              confidence and support.
            </Text>
            <img src={ForumImage} alt="" className="image" />
          </div>
        </div>
      </Container>
    </div>
  );
}
