import { Container } from "@mui/material";

import { Text } from "@mantine/core";

import HealthcareProfessionalImage from "../../../Assets/IMG/HealthcareProfessional.png";

export default function HealthcareProfessionals() {
  return (
    <div className="healthcare-professionals flex-row align-center justify-center width-100">
      <Container maxWidth="lg">
        <Text className="header">For Healthcare Professionals</Text>
        <div className="main flex-row width-100 justify-between">
          <div className="left flex-col" data-aos="fade-right">
            <div className="item flex-row">
              <div className="number-container flex-row align-center justify-center">
                <Text className="number">01</Text>
              </div>
              <div className="content flex-col">
                <Text className="title">
                  Get access to a cost effective AI-Powered system for
                  diagnosing Diabetic retinopathy
                </Text>
                <Text className="description">
                  Our cutting-edge platform harnesses the power of artificial
                  intelligence to provide accurate and efficient diagnoses,
                  empowering healthcare professionals to deliver timely
                  interventions and personalized treatment plans. With our
                  system, healthcare providers can streamline their workflow,
                  reduce diagnostic errors, and improve patient outcomes.
                </Text>
              </div>
            </div>
            <div className="item flex-row">
              <div className="number-container flex-row align-center justify-center">
                <Text className="number">02</Text>
              </div>
              <div className="content flex-col">
                <Text className="title">
                  Manage your patient appointment seamlessly with out AI-Powered
                  appointment scheduler
                </Text>
                <Text className="description">
                  Our cutting-edge platform harnesses the power of artificial
                  intelligence to provide accurate and efficient diagnoses,
                  empowering healthcare professionals to deliver timely
                  interventions and personalized treatment plans. With our
                  system, healthcare providers can streamline their workflow,
                  reduce diagnostic errors, and improve patient outcomes.
                </Text>
              </div>
            </div>
          </div>
          <img
            data-aos="fade-left"
            src={HealthcareProfessionalImage}
            alt=""
            className="image"
          />
        </div>
      </Container>
    </div>
  );
}
