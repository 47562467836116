import { Container } from "@mui/material";
import Grid, { GridProps } from "@mui/material/Grid";

import { Text } from "@mantine/core";

import JagaJagaImage from "../../../Assets/IMG/JagaJagaLine.svg";
import MissionIcon from "../../../Assets/IMG/MissionIcon.svg";

import InnovationIcon from "../../../Assets/IMG/Values/Innovation.svg";
import EmpathyIcon from "../../../Assets/IMG/Values/Empathy.svg";
import CollaborationIcon from "../../../Assets/IMG/Values/Collaboration.svg";
import AccessibilityIcon from "../../../Assets/IMG/Values/Accessibility.svg";

export default function WhoAreWe() {
  const values = [
    {
      title: "Innovation",
      icon: InnovationIcon,
      description:
        "Continuously integrate the latest technologies to enhance our \b services",
    },
    {
      title: "Empathy",
      icon: EmpathyIcon,
      description:
        "Understand and cater to the diverse needs of diabetic patients with compassion",
    },
    {
      title: "Collaboration",
      icon: CollaborationIcon,
      description:
        "Foster partnerships with healthcare institutions and organizations to amplify our impact",
    },
    {
      title: "Accessibility",
      icon: AccessibilityIcon,
      description:
        "Ensure our solutions are accessible to all, irrespective of geographical or socioeconomic factors",
    },
  ];
  const gridItemProps: GridProps = {
    item: true,
    xl: 6,
    lg: 6,
    md: 6,
    sm: 12,
    xs: 12,
    className: "value flex-col",
  };
  return (
    <div className="who-are-we-container  flex-row align-center justify-center width-100">
      <Container maxWidth="lg">
        <div className="flex-col align-center justify-center width-100">
          <Text className="header">Who are We</Text>
          <img src={JagaJagaImage} alt="" className="line" />
        </div>
        <div className="statements flex-row width-100 align-center justify-between">
          <div className="statement flex-col statement-blue" data-aos="fade-up">
            <div className="row align-center flex-row">
              <img src={MissionIcon} alt="" className="icon" />
              <Text className="title">Our Mission Statement</Text>
            </div>
            <Text className="description">
              Empower individuals living with diabetes through cutting-edge
              technology, fostering a community of support, and facilitating
              proactive and personalized care.
            </Text>
          </div>
          <div
            className="statement flex-col statement-orange"
            data-aos="fade-up"
          >
            <div className="row align-center flex-row">
              <img src={MissionIcon} alt="" className="icon" />
              <Text className="title">Our Vision Statement</Text>
            </div>
            <Text className="description">
              To be the leading AI-Powered ecosystem for diabetic care in
              Africa, providing accessible, comprehensive, and personalized
              solutions for patients.
            </Text>
          </div>
        </div>
        <div className="core-values flex-col" data-aos="fade-up">
          <Container maxWidth="md">
            <div className="flex-col align-center justify-center width-100">
              <Text className="subheader">Core Values</Text>
              <img src={JagaJagaImage} alt="" className="line" />
            </div>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="values"
            >
              {values.map((value) => {
                return (
                  <Grid key={value.title} {...gridItemProps}>
                    <div className="row flex-row align-center">
                      <img src={value.icon} alt="" className="icon" />
                      <Text className="title">{value.title}</Text>
                    </div>
                    <Text className="description">{value.description}</Text>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </div>
      </Container>
    </div>
  );
}
