import { Container } from "@mui/material";
import { Text } from "@mantine/core";

import AbdulmalikImage from "../../Assets/IMG/About/People/Abdulmalik.jpg";
import FavourImage from "../../Assets/IMG/About/People/Favour.jpg";
import KhalifaImage from "../../Assets/IMG/About/People/Khalifa.jpg";
import MololuwaImage from "../../Assets/IMG/About/People/Mololuwa.jpg";

import "./styles.scss";
export default function AboutUs() {
  const people = [
    {
      name: "Abdulmalik Adeyemo",
      position: "Founder / CEO",
      image: AbdulmalikImage,
      description:
        "Abdulmalik Adeyemo is a machine learning researcher focused on the application of cutting-edge Machine Learning and Artificial Intelligence techniques to concrete problems in healthcare. He earned a First Class Bachelor's degree in Computer Engineering from the University of Ilorin and was branded the department's best graduating student. He is actively involved in the use of artificial intelligence to improve healthcare service and delivery in Africa. He has worked on several AI in healthcare projects, including AI-assisted chest X-ray diagnosis, brain tumor detection from MRI scans, and Diabetic Retinopathy detection from fundus images",
    },
    {
      name: "Khalifa Shuaib",
      position: "Co-Founder / CTO",
      image: KhalifaImage,
      description:
        "In the world where technology meets health, Khalifa Shuaib is an innovative pioneer.  Holding a first-class bachelor’s degree in Mechatronics Engineering, Khalifa boasts a comprehensive skill set in computer-aided design, software engineering, embedded systems, and electronics. His prowess in these areas has been honed over years of hands-on experience, making him a proficient individual in the realm of technology-driven healthcare solutions. <br/> <br/> This breadth of skill finds practical application at FundusAI, standing at the forefront of health tech with a dedicated focus on revolutionizing diabetes care through Artificial Intelligence. Beyond FundusAI, Khalifa co-founded elanosia-AI, a startup with a mission to achieve rapid diagnosis of malaria, showcasing a broader vision of addressing critical healthcare challenges on a global scale and Future Fish Farms, an AgriTech company that uses 4th Industrial Revolution Technology to simplify traditional aquaculture practices. <br /> <br /> Khalifa's role as a serial innovator is underscored by his involvement in multiple projects aimed at solving contemporary challenges. In Khalifa Shuaib, FundusAI has found not just a CTO but a visionary leader, a serial innovator, and a compassionate technologist. Khalifa's journey signifies a commitment to deploying technology for transformative impact.",
    },
    {
      name: "Favour Udofiah",
      position: "Co-Founder / CFO",
      image: FavourImage,
      description:
        "Udofiah Favour Imabasi is a co-founder and the Chief Financial Officer at FundusAI LTD. As a Computer Science graduate and Data Scientist, she brings a holistic perspective to financial management within the health tech sector. <br /> <br/>Favour has held various leadership roles in business development and financial management across diverse organizations. <br /> <br/> With dual expertise in technology and finance, she plays a pivotal role in aligning FundusAI's financial strategies with technological innovation for a synergistic approach to success.  As a multifaceted professional with expertise spanning across finance, data science and public relations, Favour stays ahead of industry trends to deliver innovative PR solutions, craft compelling narratives and enhance brand reputation.",
    },
    {
      name: "Mololuwa Adeniyi",
      position: "Co-Founder / COO",
      image: MololuwaImage,
      description:
        "Mololuwa Adeniyi is a skilled robotics engineer navigating the cutting-edge realms of technology. Rooted in Mechatronics Engineering and fueled by a passion for automation, Mololuwa crafts innovative solutions like TryKey, a sensor-based protocol that helps investors secure yield on their investments. At the forefront of technological evolution, Mololuwa is not just a robotics engineer; he's a multifaceted professional: a proficient Web 3.0 developer, a car enthusiast, and an expert in business management and finance. <br/> As the Co-founder and Chief Operations Officer at FUNDUS AI, Mololuwa takes the lead in operations and business management. In addition to his pivotal role at FUNDUS AI, Mololuwa dons another hat as a blockchain developer at the lazyutesclub. This community-based project leverages emerging technology to provide a platform for youths in Nigeria to excel.",
    },
  ];
  return (
    <div className="about-us-container">
      <div className="jumbotron-placeholder"></div>
      <div className="jumbotron">
        <Container maxWidth="lg">
          <Text className="header">Meet the FundusAI Team</Text>
        </Container>
      </div>
      <Container maxWidth="md">
        <div className="main flex-col width-100">
          {people.map((person, index) => {
            return (
              <div
                key={`${index} - ${person.name}`}
                className="person flex-col width-100"
              >
                <img src={person.image} alt="" className="image" />
                <Text className="name">{person.name}</Text>
                <Text className="name position">{person.position}</Text>
                <Text className="description">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: person.description,
                    }}
                  ></div>
                </Text>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}
