import { Container } from "@mui/material";
import { Text, Button } from "@mantine/core";

import AppStoreIcon from "../../../Assets/IMG/AppStore.svg";
import PlayStoreIcon from "../../../Assets/IMG/PlayStore.svg";
import CardSuffixImage from "../../../Assets/IMG/Cards/CardSuffix2.svg";
import BossWomanNavigatingImage from "../../../Assets/IMG/Cards/BossWomanNavigating.png";

export default function AppBanner() {
  return (
    <div className="app-banner-container">
      <Container maxWidth="lg">
        <div className="main flex-row width-100 justify-between">
          <div
            className="left flex-col align-center justify-center"
            data-aos="fade-right"
          >
            <div className="content flex-col">
              <img src={CardSuffixImage} alt="" className="suffix-image" />
              <Text className="title">
                Let's Navigate Diabetes <br />
                Together - Download the <br /> FundusAI App
              </Text>
              <Button
                className="download"
                leftSection={<img className="icon" src={PlayStoreIcon} />}
              >
                Download on Google Play
              </Button>
              <Button
                className="download"
                leftSection={<img className="icon" src={AppStoreIcon} />}
              >
                Download on App Store
              </Button>
              <div className="flex-row width-100 align-end justify-end">
                <img src={CardSuffixImage} alt="" className="suffix-image" />
              </div>
            </div>
          </div>
          <img
            data-aos="fade-left"
            src={BossWomanNavigatingImage}
            alt=""
            className="navigator-image"
          />
        </div>
      </Container>
    </div>
  );
}
