import { useLocation } from "react-router-dom";

import { Container, Grid, GridProps } from "@mui/material";
import { Text } from "@mantine/core";

import Logo from "../../Assets/IMG/Logo.png";

import EnvelopeIcon from "../../Assets/IMG/Footer/Envelope.svg";
import TelephoneIcon from "../../Assets/IMG/Footer/Telephone.svg";
import HomeIcon from "../../Assets/IMG/Footer/Home.svg";
import InstagramIcon from "../../Assets/IMG/Footer/Instagram.svg";
import TwitterIcon from "../../Assets/IMG/Footer/Twitter.svg";
import FacebookIcon from "../../Assets/IMG/Footer/Facebook.svg";

import "./styles.scss";
export default function Footer() {
  const footerMenuItems = [
    {
      title: "Product",
      items: [
        { label: "Features", link: "" },
        { label: "Pricing", link: "" },
        { label: "Case Studies", link: "" },
        { label: "Reviews", link: "" },
        { label: "Updates", link: "" },
      ],
    },
    {
      title: "Company",
      items: [
        { label: "About", link: "" },
        { label: "Contact Us", link: "" },
        { label: "Careers", link: "" },
        { label: "Culture", link: "" },
        { label: "Blog", link: "" },
      ],
    },
    {
      title: "Support",
      items: [
        { label: "Getting Started", link: "" },
        { label: "Help center", link: "" },
        { label: "Server status", link: "" },
        { label: "Report a bug", link: "" },
        { label: "Chat support", link: "" },
      ],
    },
  ];
  const gridItemProps: GridProps = {
    item: true,
    xl: 4,
    lg: 4,
    md: 6,
    sm: 6,
    xs: 6,
  };
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.replaceAll("/", "");
  return (
    <>
      {path !== "chat" && (
        <div className="footer-container" id="contact">
          <Container maxWidth="lg">
            <img src={Logo} className="logo" alt="" />
            <div className="footer flex-row justify-between width-100">
              <div className="left flex-col">
                <Text className="header">Get in touch</Text>
                <div className="item flex-row align-center">
                  <img src={EnvelopeIcon} alt="" className="icon" />
                  <a href="mailto:info@fundusai.com">
                    <Text className="label">Info@fundusai.com</Text>
                  </a>
                </div>
                <div className="item flex-row align-center">
                  <img src={TelephoneIcon} alt="" className="icon" />
                  <a href="tel:+2348108273624">
                    <Text className="label">+234 (810) 827-3624</Text>
                  </a>
                </div>
                <div className="item flex-row align-center">
                  <img src={HomeIcon} alt="" className="icon" />
                  <Text className="label">
                    2nd floor, National Center for Artificial Intelligence and
                    Robotics, Wuye, Abuja.
                  </Text>
                </div>
                <div className="social flex-row align-center">
                  <a target="_blank" href="https://instagram.com/theajstars">
                    <img src={InstagramIcon} alt="" className="social-icon" />
                  </a>
                  <a target="_blank" href="https://instagram.com/theajstars">
                    <img src={FacebookIcon} alt="" className="social-icon" />
                  </a>
                  <a target="_blank" href="https://instagram.com/theajstars">
                    <img src={TwitterIcon} alt="" className="social-icon" />
                  </a>
                </div>
              </div>
              <Grid
                className="right"
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {footerMenuItems.map((footerMenu, index) => {
                  return (
                    <Grid key={footerMenu.title} {...gridItemProps}>
                      <div className="col flex-col">
                        <div className="col-items flex-col">
                          <Text className="col-header">{footerMenu.title}</Text>
                          {footerMenu.items.map((item) => {
                            return (
                              <a
                                href="#"
                                className="footer-link"
                                key={item.label}
                              >
                                {item.label}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <div className="bottom flex-row align-center justify-between width-100">
              <Text className="copy">&copy; Copyright 2024</Text>
              <div className="flex-row row align-center">
                <a href="#">
                  <Text className="label">All Rights Reserved</Text>
                </a>
                <span className="divider">|</span>
                <a href="#">
                  <Text className="label">Terms and Conditions</Text>
                </a>
                <span className="divider">|</span>
                <a href="#">
                  <Text className="label">Privacy Policy</Text>
                </a>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}
