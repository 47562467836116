import { useState } from "react";

import { Container } from "@mui/material";
import { Text, Button, TextInput } from "@mantine/core";

import { useForm } from "@mantine/form";

import { motion } from "framer-motion";

import "./styles.scss";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
} from "../../../Lib/Methods";
import { PerformRequest } from "../../../Lib/usePerformRequest";
import { Endpoints } from "../../../Lib/Endpoints";
export default function Waitlist() {
  const [formError, setFormError] = useState<boolean>(false);
  const form = useForm({
    initialValues: {
      email: "",
      name: "",
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Please enter a valid email",
      name: (value) => (value.length > 0 ? null : "Please enter your name"),
    },
    onValuesChange: (values, prev) => {
      if (formError) {
        validateForm();
      }
    },
  });
  const validateForm = () => {
    const { hasErrors } = form.validate();

    setFormError(hasErrors);
    return hasErrors;
  };
  const [isLoading, setLoading] = useState<boolean>(false);
  const JoinWaitlist = async () => {
    const hasErrors = validateForm();
    console.log(hasErrors, form);
    if (!hasErrors) {
      setLoading(true);
      const { name, email } = form.values;
      const r = await PerformRequest<any>({
        method: "POST",
        route: Endpoints.JoinWaitlist,
        data: { email, name },
      });
      setLoading(false);
      if (r && r.status === 200) {
        DefaultSuccessNotification("You have been added to our waitlist!");
      }
    }
  };
  return (
    <div className="waitlist-container" id="waitlist">
      <Container maxWidth="lg">
        <Text className="header">Join our Waitlist</Text>
        <form
          action="#"
          onSubmit={(e) => {
            e.preventDefault();
            JoinWaitlist();
          }}
          className="form"
        >
          <motion.div
            initial={false}
            animate={{
              height: formError ? "220px" : "180px",
            }}
            className="content flex-col align-center justify-between"
          >
            <TextInput
              {...form.getInputProps("name")}
              w="100%"
              name="name"
              placeholder="Full Name"
              spellCheck={false}
              label="Name"
              classNames={{
                input: "input",
                wrapper: "input-wrapper",
              }}
            />
            <TextInput
              {...form.getInputProps("email")}
              name="email"
              w="100%"
              placeholder="Email Address"
              spellCheck={false}
              label="Email"
              classNames={{
                input: "input",
                wrapper: "input-wrapper",
              }}
            />
            <Button
              className="button"
              fullWidth
              onClick={JoinWaitlist}
              loading={isLoading}
            >
              Submit
            </Button>
          </motion.div>
        </form>
      </Container>
    </div>
  );
}
