import { Container, Grid, GridProps } from "@mui/material";

import { Text } from "@mantine/core";

import AbbottImage from "../../../Assets/IMG/EnhancedDiabetes/Abbott.svg";
import AccuCheckImage from "../../../Assets/IMG/EnhancedDiabetes/AccuCheck.svg";
import AmazFitImage from "../../../Assets/IMG/EnhancedDiabetes/AmazFit.svg";
import BetaChekImage from "../../../Assets/IMG/EnhancedDiabetes/BetaChek.svg";

export default function EnhancedDiabetesManagement() {
  const items = [
    { title: "Abbott FreeStyle Libre 1", image: AbbottImage, color: "blue" },
    { title: "AmazFit Bip", image: AmazFitImage, color: "orange" },
    { title: "Accu-Chek Instant", image: AccuCheckImage, color: "orange" },
    { title: "BETACHEK® C50", image: BetaChekImage, color: "blue" },
  ];
  const gridItemProps: GridProps = {
    item: true,
    xl: 6,
    lg: 6,
    md: 6,
    sm: 12,
    xs: 12,
  };
  return (
    <div className="enhanced-diabetes-container" data-aos="fade-up">
      <Container maxWidth="lg">
        <Text className="header">
          Enhance Your Diabetes Management: Sync <br /> Compatible Devices with
          FundusAI
        </Text>
        <Grid container spacing={4} className="items">
          {items.map((item) => {
            return (
              <Grid {...gridItemProps} key={item.title}>
                <div
                  className={`item flex-row align-center item-${item.color}`}
                >
                  <img src={item.image} alt="" className="image" />
                  <Text className="title">{item.title}</Text>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
