import { Link } from "react-router-dom";

import { Container } from "@mui/material";
import { Text, Button } from "@mantine/core";

import FinImage from "../../../Assets/IMG/FinImage.svg";
export default function Fin() {
  return (
    <div className="fin-container">
      <Container maxWidth="lg">
        <Text className="header">
          Meet Fin: Your FundusAI Ecosystem Chatbot
        </Text>
        <div
          className="main flex-row justify-between align-end width-100"
          data-aos="fade-right"
        >
          <img src={FinImage} alt="" className="image" />
          <div className="right flex-col" data-aos="fade-left">
            <Text className="title">
              Your Comprehensive Diabetes & <br /> FundusAI Ecosystem Chatbot
            </Text>
            <Text className="title small-title">
              Your Comprehensive Diabetes & FundusAI Ecosystem Chatbot
            </Text>
            <div className="description">
              Fin is your all-in-one virtual assistant, ready to answer your
              inquiries about diabetes management and guide you through the
              FundusAI ecosystem seamlessly. Whether you're seeking information
              on diabetes symptoms, treatment options, or lifestyle tips, or
              need assistance navigating the FundusAI platform, Fin has you
              covered. With Fin's expertise, you can get personalized insights
              on managing diabetes effectively, as well as receive step-by-step
              guidance on utilizing FundusAI's features, syncing compatible
              devices, earning rewards, and more.
            </div>
            <Link to="/chat">
              <Button className="download">Chat with Fin</Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}
